import React, { useEffect, useRef, useState } from 'react';
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Flex,
  Heading,
  HStack,
  Input,
  InputGroup,
  InputLeftElement,
  SimpleGrid,
  Table,
  Tag,
  Tbody,
  Td,
  Text,
  Thead,
  Tr,
  VStack,
  Button,
} from '@chakra-ui/react';
import { SearchIcon } from '@chakra-ui/icons';
import { FaBolt } from 'react-icons/fa';
import { captureException } from '@sentry/nextjs';
import { useTranslation } from 'next-i18next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { GetStaticPaths, GetStaticProps } from 'next';
import { useRouter } from 'next/router';

import Navigation from '@components/Navigation';
import projectService from '@services/ProjectService';
import { ProjectInfo } from '@projectTypes/Project';
import config from '@config';
import { Meta } from '@components/Meta';
import { safeParseJSON } from '@helpers/safeParseJSON';
import { CustomPage } from '@projectTypes/index';
import useIsEn from '@hooks/useIsEn';
import Link from 'next/link';

const compact = <T,>(a: (T | false | undefined | null)[]): T[] => {
  return a.filter(Boolean) as T[];
};

export interface ShowcaseProps {
  projects?: (ProjectInfo & { training: { id: string } })[];
  error?: any;
  year?: any;
}

const Showcase: CustomPage<ShowcaseProps> = (props) => {
  const { projects, error, year } = props;
  const { locale } = useRouter();

  const getChatUrl = (id: string) => {
    return `${config.host}/${locale}/chat/${id}`;
  };

  const { t } = useTranslation('common');

  const isEn = useIsEn();

  const sortedProjects = projects.sort((a, b) => {
    if (isEn) {
      return b.infoEn.length - a.infoEn.length;
    }
    return b.info.length - a.info.length;
  });
  const showcaseProjects = compact(
    projects
      .filter((a) => a.isHighlight)
      .map((project) => {
        if (project.info) {
          try {
            const data = JSON.parse(project.info);
            const desc = data[config.projectInfo[0].key];
            return { ...project, text: desc };
          } catch (err) {
            return false;
          }
        }
        return false;
      }),
  );

  const [search, setSearch] = useState('');
  const filtered = sortedProjects.filter((p) => {
    if (!search) {
      return true;
    }
    const s = search.toLowerCase().split(' ');
    const isTitle = s.some((w) => p.name.toLowerCase().includes(w));
    const isSchool = s.some((w) => p.schoolName?.toLowerCase().includes(w));

    return isTitle || isSchool;
  });

  const ref = useRef<HTMLHeadingElement>(null);

  const [isClient, setIsClient] = useState(false)
 
  useEffect(() => {
    setIsClient(true)
  }, [])

  if (!isClient) {
    return null;
  }

  if (error) {
    return <>Error loading data</>;
  }

  return (
    <Box bg="white" pb="40" minH="100vh" width="full">
      <Navigation />
      <Meta
        title={t('Final projects | STEMI')}
        description="Final projects of the pilot project 'School of The Future', AI chatbots on the topic of achieving UN sustainability goals"
        imageUrl={`${config.host}/images/showcase-home-2022.png`}
      />

      <VStack w="100%" maxW="1400px" mt="6" marginX="auto" spacing="1">
        <Heading size="lg">
          {t('Award-winning projects')} {year}
        </Heading>
        <SimpleGrid columns={[1, 2, 3]} gridGap="4" p="4">
          {showcaseProjects.map((sp: any) => {
            if (sp) {
              const info = safeParseJSON(sp.info);
              const infoEn = safeParseJSON(sp.infoEn);
              const tag =
                config.projectGoals.find(
                  (g) => g.key === info[config.projectGoal],
                ) || sp.finalProjectType;
              const winCategory = config.chatbotCategory[sp.name];

              const shortDescription =
                (isEn
                  ? infoEn
                    ? infoEn[config.shortDescription]
                    : null
                  : null) || info[config.shortDescription];

              const language = sp.botLanguage
                ? sp.botLanguage.slice(0, 2).toUpperCase()
                : 'HR';

              return (
                <Link key={sp.id} href={getChatUrl(sp.id)}>
                  <VStack
                    p="4"
                    h="full"
                    textAlign="center"
                    borderWidth="md"
                    shadow="md"
                    borderRadius="md"
                    bg="white"
                    position="relative"
                  >
                    {tag ? (
                      <HStack>
                        <Tag colorScheme={tag.color || 'yellow'}>
                          {winCategory ||
                            tag.label ||
                            (tag.toUpperCase ? tag.toUpperCase() : tag)}
                        </Tag>
                        {sp.keepLive && (
                          <Tag colorScheme="yellow">
                            <FaBolt />
                          </Tag>
                        )}
                        {isEn && <Tag colorScheme="gray">{language}</Tag>}
                      </HStack>
                    ) : (
                      <Tag>No tag set yet</Tag>
                    )}

                    <Heading size="md" color="black">
                      {sp.name}
                    </Heading>
                    <div>{shortDescription}</div>
                  </VStack>
                </Link>
              );
            }

            return null;
          })}
        </SimpleGrid>

        <Heading size="lg" ref={ref}>
          {t('All projects')}
        </Heading>
        <Table w="95%" maxW="800px">
          <Thead>
            <Tr>
              <Td colSpan={2}>
                <InputGroup>
                  <InputLeftElement>
                    <SearchIcon />
                  </InputLeftElement>
                  <Input
                    onChange={(e) => setSearch(e.target.value)}
                    onFocus={() => {
                      if (ref.current) {
                        ref.current.scrollTop = 0;
                      }
                    }}
                    value={search}
                    placeholder={t('Search')}
                  />
                </InputGroup>
              </Td>
            </Tr>
          </Thead>
          <Tbody>
            {filtered.map((p) => {
              const info = safeParseJSON(p.info);
              const infoEn = safeParseJSON(p.infoEn);
              const tag = config.projectGoals.find(
                (g) => g.key === info[config.projectGoal],
              );
              const shortDescription =
                (isEn
                  ? infoEn
                    ? infoEn[config.shortDescription]
                    : null
                  : null) || info[config.shortDescription];
              const language = p.botLanguage
                ? p.botLanguage.slice(0, 2).toUpperCase()
                : 'HR';
              const projectType = p.finalProjectType;

              return (
                <Tr
                  key={p.id}
                  _hover={{
                    transform: 'scale(1.02)',
                    transitionDuration: '0.1s',
                  }}
                >
                  <Link href={getChatUrl(p.id)}>
                    <Td w="full" padding={[2, 10]}>
                      <VStack align="flex-start">
                        <Flex flexDir="row" align="flex-start">
                          {!tag && projectType && (
                            <Tag colorScheme="blue" mr="2" mb="2">
                              {projectType.toUpperCase
                                ? projectType.toUpperCase()
                                : projectType}
                            </Tag>
                          )}
                          {tag && (
                            <Tag colorScheme={tag.color} mr="2" mb="2">
                              {tag.label}
                            </Tag>
                          )}
                          {p.keepLive && (
                            <Tag mr="2" colorScheme="yellow">
                              <FaBolt />
                            </Tag>
                          )}
                          {isEn && <Tag colorScheme="gray">{language}</Tag>}
                        </Flex>

                        <Heading size="md" color="black">
                          {p.name}
                        </Heading>

                        <Heading size="sm">{p.schoolName}</Heading>
                        <Text>{shortDescription}</Text>
                      </VStack>
                    </Td>
                  </Link>
                  <Td padding={[2, 10]}>
                    <Link href={getChatUrl(p.id)}>
                      <Button colorScheme="blue">Chat</Button>
                    </Link>
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
        {projects?.length ? null : (
          <Alert status="error">
            <AlertIcon />
            <AlertTitle mr={2}>No projects found!</AlertTitle>
            <AlertDescription>Coming soon!</AlertDescription>
          </Alert>
        )}
      </VStack>
    </Box>
  );
};

export const getStaticPaths: GetStaticPaths = async () => {
  return {
    paths: [
      { params: { year: '2021' } },
      { params: { year: '2022' } },
      { params: { year: '2023' } },
      { params: { year: '2024' } },
    ],
    fallback: 'blocking',
  };
};

export const getStaticProps: GetStaticProps = async (p) => {
  const { locale, params } = p;

  const translations = await serverSideTranslations(locale, ['common']);

  try {
    const projects = await projectService.getShowcaseProjects(
      params.year
        ? parseInt(params.year as string, 10)
        : new Date().getFullYear(),
    );

    return {
      props: { projects, year: params.year, ...translations },
      revalidate: 60,
    };
  } catch (error) {
    if (process.env.NODE_ENV === 'development') {
      // eslint-disable-next-line no-console
      console.error(error);
    }

    captureException(error);

    return {
      props: {
        projects: [],
        error: error.message || error.name || 'Error (check console)',
        ...translations,
      },
      revalidate: 1,
    };
  }
};

export default Showcase;
