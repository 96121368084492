import React from 'react';
import Head from 'next/head';

interface MetaProps {
  title: string;
  description?: string;
  imageUrl?: string;
}

export const Meta: React.FC<MetaProps> = (props) => {
  const { title, description, imageUrl } = props;

  const url = 'https://chatbot.stemi.education/showcase';

  return (
    <Head>
      <title>{title}</title>
      <link rel="icon" href="/images/favicon.ico" />
      <meta name="viewport" content="initial-scale=1.0, width=device-width" />
      <meta name="title" content={title} />
      <meta name="description" content={description} />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={url} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={imageUrl} />
      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:url" content={url} />
      <meta property="twitter:title" content={title} />
      <meta property="twitter:description" content={description} />
      <meta property="twitter:image" content={imageUrl} />
    </Head>
  );
};
