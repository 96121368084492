import { Box, Button, Spinner } from '@chakra-ui/react';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

const LocaleSelect = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { locale, locales, asPath, pathname, query, push } = useRouter();
  const shortLocales = locales.map((l) => l.slice(0, 2));
  const shortLocale = locale.slice(0, 2);
  const localeIndex = shortLocales.indexOf(shortLocale) || 0;

  useEffect(() => {
    setIsLoading(false);
  }, [locale]);

  const changeLocale = () => {
    setIsLoading(true);
    const nextLocale = locales[(localeIndex + 1) % locales.length];
    push({ pathname, query }, asPath, { locale: nextLocale });
  };

  return (
    <Box>
      {isLoading ? (
        <Spinner />
      ) : (
        <Button onClick={changeLocale}>{shortLocale.toUpperCase()}</Button>
      )}
    </Box>
  );
};

export default LocaleSelect;
